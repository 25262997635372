<template>
  <div class="gg-container">
    <el-form
      ref="ruleForm"
      :rules="rules"
      :model="form"
      label-width="150px"
      v-loading="loading"
      class="demo-ruleForm"
      :show-message="false"
      :validate-on-rule-change="false"
    >
    <h1>系统配置</h1>
      <div>
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <el-form-item label="回收客服工号">
                <el-select
                  clearable
                  placeholder="请选择"
                  size="small"
                  v-model="form.recovery_kf"
                >
                  <el-option
                    v-for="item in staff"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-col>
        
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <el-form-item label="电商客服">
                <el-select
                  clearable
                  placeholder="请选择"
                  size="small"
                  v-model="form.mall_staff_id"
                >
                  <el-option
                    v-for="item in staff"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">      
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <el-form-item label="线索咨询商品">
                <el-select
                  clearable
                  placeholder="请选择"
                  size="small"
                  v-model="form.thread_goods_id"
                >
                  <el-option
                    v-for="item in goods"
                    :key="item.goods_id"
                    :label="item.goods_name"
                    :value="item.goods_id"
                  >
                  </el-option>
                </el-select>
              </el-form-item></div
          ></el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="grid-content bg-purple" style="position: relative;">
              <el-tooltip class="item" effect="dark" placement="top">
                <div slot="content">客服自助创建档案是否自动分配给自己</div>
                <i class="el-icon-warning-outline" style="color:red;cursor:pointer;position:absolute;top:12px;left:0px;"></i>
              </el-tooltip>
              <el-form-item label="客服建档自动分配" prop="auto_assign_status">
                <el-radio-group v-model="form.auto_assign_status" size="small">
                  <el-radio :label="1" border>是</el-radio>
                  <el-radio :label="0" border>否</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </el-col>

          <el-col :span="8">
           
            <div class="grid-content bg-purple" style="position: relative;">
              <el-tooltip class="item" effect="dark" placement="top">
                <div slot="content">客服修改档案资料，是否需要文员审核</div>
                <i class="el-icon-warning-outline" style="color:red;cursor:pointer;position:absolute;top:12px;left:30px;"></i>
              </el-tooltip>
              
              <el-form-item label="档案审核状态" prop="contract_examine_status">  
                <el-radio-group v-model="form.contract_examine_status" size="small">
                  <el-radio :label="1" border>是</el-radio>
                  <el-radio :label="0" border>否</el-radio>
                </el-radio-group>
                <!-- <span style="color:#666666;font-size:12px;">（客服创建档案是否自动分配给自己）</span>  -->
              </el-form-item>
            </div>
          </el-col>
         
          <el-col :span="8">
           
           <div class="grid-content bg-purple" style="position: relative;">
             <el-tooltip class="item" effect="dark" placement="top">
               <div slot="content">是否显示档案客户标签</div>
               <i class="el-icon-warning-outline" style="color:red;cursor:pointer;position:absolute;top:12px;left:30px;"></i>
             </el-tooltip>
             
             <el-form-item label="档案标签状态" prop="tag_status">  
               <el-radio-group v-model="form.tag_status" size="small">
                 <el-radio :label="1" border>是</el-radio>
                 <el-radio :label="0" border>否</el-radio>
               </el-radio-group>
               <!-- <span style="color:#666666;font-size:12px;">（客服创建档案是否自动分配给自己）</span>  -->
             </el-form-item>
           </div>
         </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <el-form-item label="IP电话" prop="ip_sync_status">
                <el-radio-group v-model="form.ip_sync_status" size="small">
                  <el-radio :label="1" border>开启</el-radio>
                  <el-radio :label="0" border>关闭</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </el-col>  
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <el-form-item label="客情APP电话类型" prop="app_mobile_type">
                <el-radio-group v-model="form.app_mobile_type" size="small">
                  <el-radio :label="1" border>手机拨打</el-radio>
                  <el-radio :label="2" border>ip电话拨打</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </el-col>          
        </el-row>

        <el-row :gutter="20" v-if="form.ip_sync_status==1">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <el-form-item label="IP电话接口地址" prop="ip_mobile_api_url">
                <el-input
                  size="small"
                  placeholder="IP电话接口地址包含端口"
                  v-model="form.ip_mobile_api_url"
                  style="width: 220px"
                  clearable
                >
               </el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <el-form-item label="IP电话aid" prop="ip_mobile_aid">
                <el-input
                  size="small"
                  placeholder="IP电话aid"
                  v-model="form.ip_mobile_aid"
                  style="width: 220px"
                  clearable
                >
               </el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <el-form-item label="IP电话key" prop="ip_mobile_key">
                <el-input
                  size="small"
                  placeholder="IP电话key"
                  v-model="form.ip_mobile_key"
                  style="width: 220px"
                  clearable
                >
               </el-input>
              </el-form-item>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <el-form-item label="预约问诊分享图">
                <customUpload
                  width="150px"
                  height="150px"
                  :fileUrl="form.share_diagnosis_pic"
                  @on-success="(file) => onSuccess(file, 'share_diagnosis_pic')"
                >
                </customUpload>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <el-form-item label="发放权益包分享图">
                <customUpload
                  width="150px"
                  height="150px"
                  :fileUrl="form.share_demand_pic"
                  @on-success="(file) => onSuccess(file, 'share_demand_pic')"
                >
                </customUpload>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <el-form-item label="订单分享图">
                <customUpload
                  width="150px"
                  height="150px"
                  :fileUrl="form.share_order_pic"
                  @on-success="(file) => onSuccess(file, 'share_order_pic')"
                >
                </customUpload>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="success" size="mini" @click="saveForm()">保存</el-button>
    </span>
  </div>
</template>

<script>
// import {
// 	saveConfig
// } from "@/api/basic/index";
import {
  getDiseaseList,
  saveConfig,
  getSettingInfo,
  getGetThreadGoods,
} from "@/api/cdm/cmd-configure.js";
import customUpload from "@/components/customUpload";
// import Tinymce from '@/components/Tinymce'
import { mapState } from "vuex";
export default {
  name: "platformConfig",
  components: {
    customUpload,
  },
  props: {
    index: {
      type: [Number, String],
    },
    title: [String],
    icon: [String],
  },
  watch: {
    index(n, v) {
      this.$refs["ruleForm"].clearValidate();
    },
  },
  mounted() {
    // this.$store.dispatch('dictionary/getdisease');
    // console.log('this.diseaseList',this.diseaseList)
  },
  created() {
    this._getSettingInfoNo();
  },
  computed: {
    ...mapState({
      is_admin: (state) => state.user.is_admin,
      // diseaseList: state => state.dictionary.diseaseList
    }),
  },
  data() {
    return {
      form: {},
      listLoading: false,
      loading: false,
      staff:[],
      goods:[],
      statusTitle: "新增",
      datetimerange: "",
    
      currInfo: {
        group_id: null,
        contraindication_name: "",
      },
      rules: {
        
      
        contract_examine_status: [
          {
            required: true,
            message: "请选择档案审核",
            trigger: 'change'
          },
        ],
        tag_status: [
          {
            required: true,
            message: "请选择客户标签",
            trigger: 'change'
          },
        ],
        ip_sync_status: [
          {
            required: true,
            message: "请选择IP电话",
            trigger: 'change'
          },
        ],
        app_mobile_type:[
          {
            required: true,
            message: "请选择APP电话类型",
            trigger: 'change'
          },
        ],
      },
      tableData: [],
    };
  },
  // eslint-disable-next-line no-dupe-keys
  mounted() {
    
  },
  methods: {
    _getSettingInfo(agent_id) {
      // this.loading = true
      let params = {
        agent_id,
      };
      return getSettingInfo(params).then();
    },
    _getSettingInfoNo(agent_id) {
      let params = {
        agent_id,
      };
      getSettingInfo(params).then((res) => {
        if (res.code == 200) {
          this.form = res.data.config;
          this.staff = res.data.staff
          this.goods = res.data.goods
        }
      });
    },
   
    onSuccess(file, name) {
      console.log("file,name", file, name);
      this.form[name] = file;
      console.log(this.form);
    },
    saveForm() {
      console.log("this.index", this.index);
      if (this.index == "1") {
        console.log(111);
        this.form.type = "diagnosis";
      } else if (this.index == "2") {
        console.log(222);
        this.form.type = "ggkq";
      } else {
        this.form.type = "rate";
      }
      this.$refs["ruleForm"].validate((v, o) => {
        console.log(v, o);
        if (!v) {
          let item;
          for (let key in o) {
            item = o[key][0];
            break;
          }
          this.$message({
            type: "error",
            message: item.message,
          });
          return;
        } else {
          this.loading = true;
          saveConfig(this.form)
            .then((res) => {
              this.loading = false;
              if (res.code == 200) {
                return this.$message.success("保存成功");
              } else {
                return this.$message.error(res.message ? res.message : res.msg);
              }
            })
            .catch((err) => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.dot {
  display: inline-block;
  border: 5px solid;
  border-radius: 50%;
  margin: 0 5px;
}

.nav-title {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin: 0 0 1px 40px;
}

.dialog-footer {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  width: 70%;
}

/deep/.switch .el-switch__label {
  position: absolute;
  display: none;
  color: #fff !important;
}

/*打开时文字位置设置*/
/deep/.switch .el-switch__label--right {
  z-index: 1;
}

/* 调整打开时文字的显示位子 */
/deep/.switch .el-switch__label--right span {
  margin-left: -1px;
  // margin-right: 40px;
}

/*关闭时文字位置设置*/
/deep/.switch .el-switch__label--left {
  z-index: 1;
}

/* 调整关闭时文字的显示位子 */
/deep/.switch .el-switch__label--left span {
  margin-left: 22px;
}

/*显示文字*/
/deep/.switch .el-switch__label.is-active {
  display: block;
}

/* 调整按钮的宽度 */
/deep/.switch.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 60px !important;
  margin: 0;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
</style>
