<template>
	<div class="gg-container" style="background-color: #f9f9f9">
		<div class="content">
			<div class="nav-right">
				<platformConfig :index="menuIndex" :title="title" :form="config"></platformConfig>
			</div>
		</div>
	</div>
</template>

<script>
	import platformConfig from "./components/platformConfig.vue";
	import {
		mapState
	} from "vuex";
	export default {
		name: "diseaseDesc",
		components: {
			platformConfig,
		},
		computed: {
			...mapState({
				is_admin: state => state.user.is_admin,
				// diseaseList: state => state.dictionary.diseaseList
			})
		},
		created() {
		
			// this.getConfig()
			// this.navs = this._getNavList();
			// this._setDictsListProp();
		},
		data() {
			return {
				curIcon: "icon-huanzhechangjing",
				config: {},
				navs: [],
				title: "平台基础",
				menuIndex: 1,
				pageTitle: "系统配置",
				menuOptions: [{
						value: 1,
						label: "问诊配置"
					},
					{
						value: 2,
						label: "客情配置"
					}
				],
				menuOptionsADMIN: [{
						value: 1,
						label: "问诊配置"
					},
					{
						value: 2,
						label: "客情配置"
					},
					{
						value: 3,
						label: "收入费率设置"
					}
				],
				isCode: true,
			};
		},
		methods: {
			selectMenu(row) {
				this.menuIndex = row.value;
				this.title = row.label;
			},
		},
	};
</script>

<style scoped lang="scss">
	.dot {
		display: inline-block;
		border: 5px solid;
		border-radius: 50%;
		margin: 0 5px;
	}

	.title-icon {
		margin-right: 15px;
		color: #fff;
		font-size: 18px;
		padding: 5px;
		border-radius: 2px;
		background: #2362fb;
	}

	.content {
		display: flex;

		.nav-left {
			background: #fff;
			width: 220px;
			border-radius: 10px;
			box-shadow: 1px 1px 3px #f9f9f9;
			margin-right: 20px;
			padding-top: 20px;

			.nav-left-box {
				position: relative;
				display: flex;
				align-items: center;
				padding-left: 25px;
				box-sizing: border-box;

				i {
					font-size: 12px;
					padding: 5px;
					border-radius: 2px;
					background: rgb(237, 242, 246);
					color: #8a94a6;
				}

				.bg1 {
					color: #fff;
					background: rgb(39, 186, 74);
				}

				.bg2 {
					color: #fff;
					background: rgb(25, 181, 246);
				}

				.bg3 {
					color: #fff;
					background: rgb(112, 74, 253);
				}

				.bg4 {
					color: #fff;
					background: rgb(255, 185, 64);
				}

				.nav-left-item {
					height: 40px;
					line-height: 40px;
					text-align: center;
					font-size: 14px;
					cursor: pointer;
					margin-left: 15px;
				}
			}

			.nav-left-box:hover {
				background: #f6f8fa;
			}

			.active:before {
				position: absolute;
				content: "";
				left: 0;
				top: 0;
				width: 2px;
				height: 100%;
				background: #2362fb;
			}

			.active {
				background: #f6f8fa;
			}
		}

		.nav-right {
			background: #fff;
			width: 100%;
		}
	}
</style>
